<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div class="overflow-scroll" style="max-height: 600px">
      <table class="shadow-lg bg-white w-full relative border-collapse">
        <tr>
          <th
            data-sort_val="_id"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            ID
          </th>
          <th
            data-sort_val="first_name"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            First Name
          </th>
          <th
            data-sort_val="last_name"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Last Name
          </th>
          <th
            data-sort_val="email"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Email
          </th>
          <th
            data-sort_val="phone"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Phone Number
          </th>
          <th
            data-sort_val="role"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Role
          </th>
          <th
            data-sort_val="user_type"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            User Type
          </th>
          <th
            data-sort_val="updated_at"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Updated At
          </th>
          <th
            data-sort_val="last_login"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Last Login
          </th>
          <th
            data-sort_val="validated"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Validated
          </th>
          <th
            data-sort_val="qb_id"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            QB ID
          </th>
          <th
            data-sort_val="password"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Password
          </th>
          <th
            data-sort_val="has_sub_collection"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Has Sub Collection
          </th>
          <th
            class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
          >
            Aggregator
          </th>
          <th
            data-sort_val="active"
            class="bg-blue-100 cursor-pointer hover:bg-blue-200 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Active
          </th>
        </tr>
        <tr v-for="(user, i) in filteredDataset" :key="i">
          <td class="border px-8 py-3 text-xs capitalize">{{ user._id }}</td>
          <td class="border px-8 py-3 text-xs capitalize">
            {{ user.first_name }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ user.last_name }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ user.email }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ user.phone }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ user.role }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ user.user_type }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ moment(user.updated_at).format("DD MMM YYYY - h:mm:ss a") }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ moment(user.last_login).format("DD MMM YYYY - h:mm:ss a") }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ user.validated }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ user.qb_id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ user.password }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ user.has_sub_collection }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ user.parent_aggregator }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ user.active }}
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-3">
      <button
        @click="prevPage(currentPage)"
        :disabled="currentPage === 1 ? isDisabled : !isDisabled"
        class="
                    
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    focus:shadow-outline
                    focus:outline-none
                    text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        :class="
          currentPage === 1 ? 'bg-blue-50 text-black' : 'bg-blue-900 text-white'
        "
      >
        &laquo; Prev
      </button>

      <button
        v-if="users.length > 99"
        @click="nextPage(currentPage)"
        class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
      >
        Next &raquo;
      </button>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: ["res", "searchDataset"],
  data() {
    return {
      isLoading: false,
      moment,
      users: null,
      currentPage: 1,
      isDisabled: true,
      tableData: [
        // "ID",
        // "First Name",
        // "Last Name",
        // "Email",
        // "Phone Number",
        // "Role",
        // "User Type",
        // "Updated At",
        // "Last Login",
        // "Validated",
        // "QB ID",
        // "Password",
        // "Has Sub Collection",
        // "Active",
      ],
    };
  },
  computed: {
    ...mapGetters(["GET_DATASET_RES"]),
    filteredDataset() {
      return this.users.filter(
        (user) =>
          user.first_name
            .toLowerCase()
            .includes(this.searchDataset.toLowerCase()) ||
          user.last_name
            .toLowerCase()
            .includes(this.searchDataset.toLowerCase())
      );
    },
  },
  created() {
    const data = this.res || [];
    this.users = data.users;
    // console.log(this.users.length);
    this.isLoading = false;
  },
  methods: {
    // sortHeader(e) {
    //   const val = e.target.textContent;
    //   for (let i = 0; i < this.tableData.length; i++) {
    //     if (
    //       val.trim().toLowerCase() === this.tableData[i].trim().toLowerCase()
    //     ) {
    //       this.users = this.users.sort((a, b) =>
    //         (a[e.target.dataset.sort_val] || "").toString().trim() >
    //         (b[e.target.dataset.sort_val] || "").toString().trim()
    //           ? 1
    //           : -1
    //       );
    //     }
    //   }
    // },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_DATASET_USERS",
        currPage - 1
      );
      if (res.status) {
        // const data = this.res || [];
        this.users = res.data.users;
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_DATASET_USERS",
        currPage + 1
      );
      if (res.status) {
        // const data = this.res || [];
        this.users = res.data.users;
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
  },
};
</script>

<style scoped>
table tr th {
  background: #f0f6fd;
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
</style>
