<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div class="overflow-scroll" style="max-height: 600px">
      <table class="shadow-lg bg-white w-full relative border-collapse">
        <tr>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Sn.
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Business ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Store ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Store Name
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Client ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Invoice Title
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Order Status
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Invoice Status
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Invoice Terms
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Invoice Terms Code
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Sent
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Sent To
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Order Percent
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Outstanding
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Total
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Units
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Opened
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Created At
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Due At
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Updated At
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Order Note
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Invoice Note
          </th>
        </tr>
        <tr v-for="(invoice, i) in filteredDataset" :key="i">
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ invoice.sn }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ invoice._id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ invoice.business }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ invoice.store_id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ invoice.store_name }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ invoice.client }}
          </td>
          <td class="border px-8 py-3 text-xs capitalize max-w-xs">
            {{ invoice.title }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.order_status }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.invoice_status }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.type.description }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.type.name }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.sent }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.sent_to }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.order_percent }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.balance.outstanding.amount }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.balance.total.amount }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ invoice.items ? invoice.items.length : 0 }} Unit(s)
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.opened }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ moment(invoice.created_at).format("DD MMM YYYY - h:mm:ss a") }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ moment(invoice.due_date).format("DD MMM YYYY - h:mm:ss a") }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ moment(invoice.updated_at).format("DD MMM YYYY - h:mm:ss a") }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.order_note }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ invoice.notes.text }}
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-3">
      <button
        @click="prevPage(currentPage)"
        :disabled="currentPage === 1 ? isDisabled : !isDisabled"
        class="
                    
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    focus:shadow-outline
                    focus:outline-none
                    text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        :class="
          currentPage === 1 ? 'bg-blue-50 text-black' : 'bg-blue-900 text-white'
        "
      >
        &laquo; Prev
      </button>

      <button
        v-if="invoices.length > 99"
        @click="nextPage(currentPage)"
        class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
      >
        Next &raquo;
      </button>
    </div>
  </main>
</template>

<script>
import moment from "moment";

export default {
  props: ["res", "searchDataset"],
  data() {
    return {
      moment,
      invoices: null,
      isLoading: false,
      currentPage: 1,
      isDisabled: true,
    };
  },
  computed: {
    filteredDataset() {
      return this.invoices.filter((inv) =>
        inv.sent_to
          ? inv.sent_to.toLowerCase().includes(this.searchDataset.toLowerCase())
          : ""
      );
    },
  },
  created() {
    this.isLoading = true;
    const data = this.res || [];
    this.invoices = data;
    this.isLoading = false;
  },
  methods: {
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      const res = await this.$store.dispatch(
        "FETCH_PAGINATED_DATASET_INVOICES",
        currPage - 1
      );
      if (res.status) {
        // const data = this.res || [];
        this.invoices = res.data;
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      const res = await this.$store.dispatch(
        "FETCH_PAGINATED_DATASET_INVOICES",
        currPage + 1
      );
      console.log(res);
      if (res.status) {
        // const data = this.res || [];
        this.invoices = res.data;
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
  },
};
</script>

<style></style>
