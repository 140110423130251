<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div class="overflow-scroll" style="max-height: 600px">
      <table class="shadow-lg bg-white w-full relative border-collapse">
        <tr>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Business Name
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Business ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Online Store Name
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Email
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Products Category
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Category ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Product Title
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Product ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Product Price
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Created At
          </th>
        </tr>
        <tr v-for="(product, i) in products" :key="i">
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ product.business_name | truncate(30, "...") }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ product.business_id | truncate(30, "...") }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ product.business_name }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ product.business_email }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ product.category }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ product.category_id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ product.title }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ product._id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ product.price }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ moment(product.created_at).format("DD MMM YYYY - h:mm:ss a") }}
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-3">
      <button
        @click="prevPage(currentPage)"
        :disabled="currentPage === 1 ? isDisabled : !isDisabled"
        class="
                    
                    uppercase
                    shadow
                    ml-3
                    
                    focus:shadow-outline
                    focus:outline-none
                    text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        :class="
          currentPage === 1
            ? 'bg-blue-50 text-black hover:bg-white'
            : 'bg-blue-900 text-white hover:bg-blue-600'
        "
      >
        &laquo; Prev
      </button>

      <button
        v-if="products.length > 99"
        @click="nextPage(currentPage)"
        class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
      >
        Next &raquo;
      </button>
    </div>
  </main>
</template>

<script>
import moment from "moment";

export default {
  props: ["res"],
  data() {
    return {
      moment,
      products: null,
      isLoading: false,
      currentPage: 1,
      isDisabled: true,
    };
  },
  created() {
    const data = this.res ? this.res.products || [] : [];
    this.products = data.sort((a, b) =>
      a.business_name.trim().toLowerCase() >
      b.business_name.trim().toLowerCase()
        ? 1
        : -1
    );
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_DATASET_PRODUCTS",
        currPage - 1
      );
      if (res.status) {
        // const data = this.res || [];
        this.products = res.data.products;
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_DATASET_PRODUCTS",
        currPage + 1
      );
      if (res.status) {
        // const data = this.res || [];
        this.products = res.data.products;
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
  },
};
</script>

<style></style>
