<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div class="overflow-scroll" style="max-height: 600px">
      <table class="shadow-lg bg-white w-full relative border-collapse">
        <tr>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Order ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Txn. ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Business ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Cart Hash
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Cart Tax
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Billing Customer
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Billing Email
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Billing Phone Number
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Customer IP Address
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Customer Note
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Created Via
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Date Created
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Date Modified
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Date Completed
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Discount Tax
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Discount Total
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Line Items
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Order Key
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Prices Include Taxes
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Shipping Postal Code
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Shipping City
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Shipping State
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Shipping Country
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Total Tax
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Total
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Status
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Date Paid
          </th>
        </tr>
        <tr v-for="(order, i) in filteredDataset" :key="i">
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ order._id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ order.id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ order.transaction_id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ order.business_id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.cart_hash }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.cart_tax }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.billing.first_name + " " + order.billing.last_name }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ order.billing.email }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.billing.phone }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.customer_ip_address }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.customer_note }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.created_via }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ moment(order.date_created).format("DD MMM YYYY - h:mm:ss a") }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ moment(order.date_modified).format("DD MMM YYYY - h:mm:ss a") }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ moment(order.date_completed).format("DD MMM YYYY - h:mm:ss a") }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.discount_tax }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.discount_total }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ (order.line_items ? order.line_items.length : 0) + "Item(s)" }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.order_key }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.prices_include_tax }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.shipping.postcode }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.shipping.city }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.shipping.state }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.shipping.country }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.total_tax }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.total }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ order.status }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ moment(order.date_paid).format("DD MMM YYYY - h:mm:ss a") }}
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-3">
      <button
        @click="prevPage(currentPage)"
        :disabled="currentPage === 1 ? isDisabled : !isDisabled"
        class="
                    
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    focus:shadow-outline
                    focus:outline-none
                    text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        :class="
          currentPage === 1 ? 'bg-blue-50 text-black' : 'bg-blue-900 text-white'
        "
      >
        &laquo; Prev
      </button>

      <button
        v-if="orders.length > 99"
        @click="nextPage(currentPage)"
        class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
      >
        Next &raquo;
      </button>
    </div>
  </main>
</template>

<script>
import moment from "moment";

export default {
  props: ["res", "searchDataset"],
  data() {
    return {
      moment,
      orders: null,
      isLoading: false,
      isDisabled: true,
      currentPage: 1,
    };
  },
  computed: {
    filteredDataset() {
      return this.orders.filter((order) => {
        return (
          order.billing.first_name
            .toLowerCase()
            .includes(this.searchDataset.toLowerCase()) ||
          order.billing.last_name
            .toLowerCase()
            .includes(this.searchDataset.toLowerCase())
        );
      });
    },
  },
  created() {
    const data = this.res ? this.res || [] : [];
    this.orders = data;
  },
  methods: {
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_DATASET_ORDERS",
        currPage - 1
      );
      if (res.status) {
        // const data = this.res || [];
        this.orders = res.data;
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_DATASET_ORDERS",
        currPage + 1
      );
      if (res.status) {
        // const data = this.res || [];
        this.orders = res.data;
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
  },
};
</script>

<style scoped>
table tr th {
  background: #f0f6fd;
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
</style>
