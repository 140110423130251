<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div class="">
      <h2 class="my-2 font-semibold text-blue-">Dataset</h2>
      <!-- component -->
      <div class="flex justify-between">
        <input
          type="search"
          v-model="searchDataset"
          class="border border-2 w-5/12 text-sm p-2"
          :placeholder="'Search ' + current.searchParam + '...'"
        />
        <!-- <button
          @click="genrateCSV"
          class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
        >
          Download {{ current.name }}
        </button> -->
      </div>

      <div class="bg-white my-4">
        <nav class="flex flex-col sm:flex-row">
          <button
            v-for="(tab, i) in tabs"
            :key="i"
            @click="switchTab(tab)"
            :class="
              current.index === tab.index
                ? 'border-gray-900 border-b-2 font-medium text-gray-900'
                : ''
            "
            class="py-4 px-6 block focus:outline-none hover:text-gray-900"
          >
            {{ tab.name }}
          </button>
        </nav>
      </div>
      <div class="" v-if="dataset">
        <d-users
          :res="dataset"
          :searchDataset="searchDataset"
          v-if="current.index === 0"
        ></d-users>
        <d-business
          :res="dataset"
          :searchDataset="searchDataset"
          v-if="current.index === 1"
        ></d-business>
        <d-sales
          :res="dataset"
          :searchDataset="searchDataset"
          v-if="current.index === 2"
        ></d-sales>
        <d-invoices
          :res="dataset"
          :searchDataset="searchDataset"
          v-if="current.index === 3"
        ></d-invoices>
        <d-orders
          :res="dataset"
          :searchDataset="searchDataset"
          v-if="current.index === 4"
        ></d-orders>
        <d-stores
          :res="dataset"
          :searchDataset="searchDataset"
          v-if="current.index === 5"
        ></d-stores>
        <d-products
          :res="dataset"
          :searchDataset="searchDataset"
          v-if="current.index === 6"
        ></d-products>
      </div>
    </div>
  </main>
</template>

<script>
import { json2excel } from "js2excel";
import moment from "moment";
import Loading from "./general-components/Loading.vue";

import Businesses from "./dataset-components/Businesses";
import Invoices from "./dataset-components/Invoices";
import Orders from "./dataset-components/Orders";
import Products from "./dataset-components/Products";
import Sales from "./dataset-components/Sales";
import Stores from "./dataset-components/Stores";
import Users from "./dataset-components/Users";
import { mapActions } from "vuex";

export default {
  components: {
    "d-business": Businesses,
    "d-invoices": Invoices,
    "d-orders": Orders,
    "d-products": Products,
    "d-sales": Sales,
    "d-stores": Stores,
    "d-users": Users,
    Loading,
  },
  data() {
    return {
      searchDataset: "",
      moment,
      isLoading: false,
      tabs: [
        {
          name: "Users",
          index: 0,
          dispatchName: "FETCH_USERS",
          searchParam: "user's name",
        },
        {
          name: "Businesses",
          index: 1,
          dispatchName: "FETCH_BUSINESSES",
          searchParam: "business name",
        },
        {
          name: "Sales",
          index: 2,
          dispatchName: "FETCH_SALES",
          searchParam: "sale",
        },
        {
          name: "Invoices",
          index: 3,
          dispatchName: "FETCH_INVOICES",
          searchParam: "sent email",
        },
        {
          name: "Orders",
          index: 4,
          dispatchName: "FETCH_ORDERS",
          searchParam: "billing customer",
        },
        {
          name: "Stores",
          index: 5,
          dispatchName: "FETCH_STORES",
          searchParam: "store name",
        },
        {
          name: "Products",
          index: 6,
          dispatchName: "FETCH_PRODUCTS",
          searchParam: "product",
        },
      ],
      dataset: null,
      current: null,
    };
  },
  async created() {
    this.current = this.tabs[0];
    this.fetchData();
  },

  methods: {
    async switchTab(tab) {
      this.current = tab;
      this.dataset = null;
      await this.fetchData();
    },
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch(this.current.dispatchName);
      const res = this.$store.getters["GET_DATASET_RES"];

      this.$store.commit("SETUP_DATASET_RES", null);

      if (res.status) {
        this.dataset = res.data;
      } else {
        alert(
          res.error ||
            res.message ||
            "Error: Please check network or contact admin."
        );
      }
      this.isLoading = false;
    },
    genrateCSV() {
      let exportData = {
        title: null,
        data: null,
      };

      exportData.title = `dataset-${this.current.name.toLowerCase()}`;

      switch (this.current.index) {
        case 0:
          exportData.data = this.forUsers();
          break;
        case 1:
          exportData.data = this.forBusiness();
          break;
        case 2:
          // exportData.data = this.forSales();
          break;
        case 3:
          exportData.data = this.forinvoices();
          break;
        case 4:
          exportData.data = this.forOrders();
          break;
        case 5:
          exportData.data = this.forStores();
          break;
        case 6:
          // exportData.data = this.forProducts();
          break;

        default:
          break;
      }

      try {
        json2excel({
          data: exportData.data,
          name: exportData.title,
          formateDate: this.moment().format("DD MMM YYYY - h:mm:ss a"),
        });
      } catch (e) {
        console.error("export error");
      }
    },
    forUsers() {
      return this.dataset.map((user) => {
        return {
          ID: user._id,
          "First Name": user.first_name,
          "Last Name": user.last_name,
          Email: user.email,
          "Phone Number": user.phone,
          Role: user.role,
          "User Type": user.user_type,
          "Updated At": moment(user.updated_at).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          "Last Login": moment(user.updated_at).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          Validated: user.validated,
          "QB ID": user.qb_id,
          Password: user.password,
          "Has Sub Collection": user.has_sub_collection,
          Active: user.active,
        };
      });
    },
    forBusiness() {
      return this.dataset.map((business) => {
        return {
          ID: business._id,
          "User ID": business.user_id,
          "First Name": business.name,
          Category: business.category,
          Email: business.email,
          "Phone Number": business.phone,
          "Pricing Group": business.pricing_group,
          "Layalty Active": business.loyalty_active,
          "Sell By Online": business.business_use_case.sell_by_invoice,
          "Sell By Invoice": business.business_use_case.sell_by_online,
          "Sell By POS": business.business_use_case.sell_by_pos,
          "Postal Code": business.address ? business.address.postal_code : "",
          Street: business.address ? business.address.street : "",
          City: business.address ? business.address.city : "",
          State: business.address ? business.address.state : "",
          Country: business.address ? business.address.country : "",
          "Created At": moment(business.created_at).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          Suspended: business.suspended,
        };
      });
    },
    forSales() {
      return this.dataset.map((user) => {
        return {};
      });
    },
    forinvoices() {
      return this.dataset.map((inv) => {
        return {
          "Sn.": inv.sn,
          ID: inv._id,
          "Business ID": inv.business,
          "Store ID": inv.store_id,
          "Store Name": inv.store_name,
          "Client ID": inv.client,
          "Invoice Title": inv.title,
          "Order Status": inv.order_status,
          "Invoice Status": inv.invoice_status,
          "Invoice Terms": inv.type.description,
          "Invoice Terms Code": inv.type.name,
          Sent: inv.sent,
          "Sent To": inv.sent_to,
          "Order Percent": inv.order_percent,
          Outstanding: inv.balance.outstanding.amount,
          Total: inv.balance.total.amount,
          Units: `${inv.items ? inv.items.length : 0} Unit(s)`,
          Opened: inv.opened,
          "Created At": moment(inv.created_at).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          "Due At": moment(inv.due_date).format("DD MMM YYYY - h:mm:ss a"),
          "Updated At": moment(inv.updated_at).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          "Order Note": inv.order_note,
          "Invoice Note": inv.notes.text,
        };
      });
    },
    forOrders() {
      return this.dataset.map((order) => {
        return {
          ID: order._id,
          "Order ID": order.id,
          "Txn. ID": order.transaction_id,
          "Business ID": order.business_id,
          "Cart Hash": order.cart_hash,
          "Cart Tax": order.cart_tax,
          "Billing Customer":
            order.billing.first_name + " " + order.billing.last_name,
          "Billing Email": order.billing.email,
          "Billing Phone Number": order.billing.phone,
          "Customer IP Address": order.customer_ip_address,
          "Customer Note": order.customer_note,
          "Created Via": order.created_via,
          "Date Created": this.moment(order.date_created).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          "Date Modified": this.moment(order.date_modified).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          "Date Completed": this.moment(order.date_completed).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          "Discount Tax": order.discount_tax,
          "Discount Total": order.discount_total,
          "Line Items":
            (order.line_items ? order.line_items.length : 0) + "Item(s)",
          "Order Key": order.order_key,
          "Prices Include Taxes": order.prices_include_tax,
          "Shipping Postal Code": order.shipping.postcode,
          "Shipping City": order.shipping.city,
          "Shipping State": order.shipping.state,
          "Shipping Country": order.shipping.country,
          "Total Tax ": order.total_tax,
          Total: order.total,
          Status: order.status,
          "Date Paid": this.moment(order.date_paid).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
        };
      });
    },
    forStores() {
      return this.dataset.map((store) => {
        return {
          ID: store._id,
          "Business ID": store.business_id,
          "Business Name": store.business_name,
          "Created At": this.moment(store.created_at).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          "Woo Store ID": store.woo_store_id,
          "Woo Store Name": store.woo_store_name,
          "Woo Store User ID": store.woo_store_user_id,
          "Woo Store Password": store.woo_store_password,
          "Woo Store Url": store.woo_store_url,
        };
      });
    },
    forProducts() {
      return this.dataset.map((user) => {
        return {};
      });
    },
  },
};
</script>

<style></style>
