<template>
  <div class="overflow-scroll">
    <table class="shadow-lg bg-white w-full relative border-collapse">
      <tr>
        <th class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2">
          First Name
        </th>
        <th class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2">
          Last Name
        </th>
        <th class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2">
          Email
        </th>
        <th class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2">
          Phone Number
        </th>
        <th class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2">
          Role
        </th>
        <th class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2">
          User Type
        </th>
        <th class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2">
          Last Login
        </th>
      </tr>
      <tr v-for="(user, i) in users" :key="i">
        <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">{{ user.first_name }}</td>
        <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">{{ user.last_name }}</td>
        <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">{{ user.email }}</td>
        <td class="border whitespace-nowrap px-8 py-3 text-xs">{{ user.phone }}</td>
        <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">{{ user.role }}</td>
        <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">{{ user.user_type }}</td>
        <td class="border whitespace-nowrap px-8 py-3 text-xs">{{ moment(user.last_login).format("DD MMM YYYY - h:mm:ss a") }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ["res"],
  data() {
    return {
      moment,
      users: null,
    };
  },
  created() {
    // const data = this.res || [];
    // this.users = data.sort((a, b) => a.first_name.trim().toLowerCase() > b.first_name.trim().toLowerCase() ? 1 : -1);
  },
};
</script>

<style></style>
