<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div class="overflow-scroll" style="max-height: 600px">
      <table class="shadow-lg bg-white w-full relative border-collapse">
        <tr>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Woo Store ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Woo Store Name
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Woo Store User ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Woo Store Password
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Woo Store Url
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Business ID
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Business Name
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Email
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Created At
          </th>
        </tr>
        <tr v-for="(onlineStore, i) in filteredDataset" :key="i">
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ onlineStore.woo_store_id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ onlineStore.woo_store_name }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ onlineStore.woo_store_user_id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ onlineStore.woo_store_password }}
          </td>
          <td
            class="border whitespace-nowrap px-8 py-3 text-xs text-blue-400 underline"
          >
            <a
              target="_blank"
              :href="
                onlineStore.woo_store_url.includes('http')
                  ? ''
                  : 'https://' + onlineStore.woo_store_url
              "
              >{{ onlineStore.woo_store_url }}</a
            >
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ onlineStore._id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{ onlineStore.business_id }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
            {{ onlineStore.business_name }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs lowercase">
            {{ onlineStore.email }}
          </td>
          <td class="border whitespace-nowrap px-8 py-3 text-xs">
            {{
              moment(onlineStore.created_at).format("DD MMM YYYY - h:mm:ss a")
            }}
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-3">
      <button
        @click="prevPage(currentPage)"
        :disabled="currentPage === 1 ? isDisabled : !isDisabled"
        class="
                    
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    focus:shadow-outline
                    focus:outline-none
                    text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        :class="
          currentPage === 1 ? 'bg-blue-50 text-black' : 'bg-blue-900 text-white'
        "
      >
        &laquo; Prev
      </button>

      <button
        v-if="onlineStore.length > 99"
        @click="nextPage(currentPage)"
        class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
      >
        Next &raquo;
      </button>
    </div>
  </main>
</template>

<script>
import moment from "moment";

export default {
  props: ["res", "searchDataset"],
  data() {
    return {
      moment,
      onlineStore: null,
      isDisabled: true,
      currentPage: 1,
      isLoading: false,
    };
  },
  computed: {
    filteredDataset() {
      return this.onlineStore.filter((store) =>
        store.woo_store_name
          .toLowerCase()
          .includes(this.searchDataset.toLowerCase())
      );
    },
  },
  created() {
    this.isLoading = true;
    const data = this.res ? this.res || [] : []; //this.res ? this.res.data || [] : [];
    this.onlineStore = data;
    this.isLoading = false;
  },
  methods: {
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_DATASET_STORES",
        currPage - 1
      );
      if (res.status) {
        // const data = this.res || [];
        this.onlineStore = res.data;
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_DATASET_STORES",
        currPage + 1
      );
      if (res.status) {
        // const data = this.res || [];
        this.onlineStore = res.data;
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
  },
};
</script>

<style scoped>
table tr th {
  background: #f0f6fd;
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
</style>
